// const values
export const COVERTYPES = {
    ARABESKA  : 'ARABESKA',
    CLEANSTEP : 'CLEANSTEP',
    CHANGABLE : 'CHANGABLE'
};

export const DIRECTIONS = {
    RIGHT: 'RIGHT',
    UP: 'UP'
};

export const DIMENSIONS = {
    MAX_WIDTH: 300,
    MAX_HEIGHT: 300
};

export const COVERTYPES_TO_STEPS = {
    [COVERTYPES.ARABESKA]: 5,
    [COVERTYPES.CLEANSTEP]: 10
};

export const KANT_BORDERS = ['left', 'top', 'right', 'bottom'];

export const CHANGES_COUNT_TEXTS = {
    [0.5]: 'раз в две недели',
    [1]: 'один раз в неделю',
    [2]: 'два раза в неделю',
    [3]: 'три раза в неделю',
    [4]: 'четыре раза в неделю',
    [5]: 'пять раз в неделю',
    [6]: 'шесть раз в неделю',
    [7]: 'семь раз в неделю',
}
