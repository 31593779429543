export const Offer = function() {
    var positions = [],
        addPosition = function(pos) {
            positions.push(pos);
        },
        deletePosition = function(index) {
            positions.splice(index, 1);
        },
        // updatePosition = function() { },
        getPositions = function(id) {
            if (id) {
                var i = positions.length,
                    found = false;
                while(i--) {
                    if (positions[i].id === id) {
                        found = true;
                        break;
                    }
                }

                if (found) {
                    return positions[i];
                } else {
                    return false;
                }

            } else {
                return positions;
            }
        };

    return {
        addPosition: addPosition,
        deletePosition: deletePosition,
        getPositions: getPositions
    };
};
