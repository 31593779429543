<template>
    <ul class="price_list" :class="{fullWidth}">
        <li v-for="(pos, key) in priceLines" :key="key">
            {{ pos.text }}
            <em style="font-size: 12px;">({{ getInfoLine(pos) }})</em>
            <span>{{ pos.priceOneChange }}</span>
            <a href="#" class="remove-tr" @click.prevent="$emit('remove', key)">Удалить</a>
        </li>

        <li class="total_price">
            Общая стоимость за 4 недели
            <span>{{ formatPrice(totalPrice.toFixed(2)) }}</span>
        </li>
    </ul>
</template>

<script>
    import {formatPrice} from "@/services/utils";
    import {CHANGES_COUNT_TEXTS} from "@/constants/covers";

    export default {
        name: "PriceListExtended",
        props: {
            priceLines: Array,
            totalPrice: Number,
            fullWidth: Boolean
        },
        data() {
            return {
                CHANGES_COUNT_TEXTS
            };
        },
        methods: {
            formatPrice,
            getInfoLine(position) {
                const parts = [];
                if (position.color) {
                    parts.push(`цвет: ${position.color}`);
                }
                if (position.count) {
                    parts.push(`${position.count} шт.`);
                }
                parts.push(this.CHANGES_COUNT_TEXTS[position.changesQty]);
                return parts.join(', ');
            }
        },
    };
</script>

<style scoped>
    .price_list {
        overflow: hidden;
        clear: both;
    }
    .price_list.fullWidth {
        width: 100% !important;
    }
    .price_list.fullWidth li {
        width: auto !important;
    }
</style>
