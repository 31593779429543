import {KANT_BORDERS} from "@/constants/covers";

export function formatPrice(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function convertCantToBorders(kant) {
    const borders = {};
    KANT_BORDERS.forEach(x => borders[x.toUpperCase()] = kant.indexOf(x) !== -1);
    return borders;
}

export function getFullFormattedDate() {
    const date = new Date();

    let month = date.getMonth();
    month = month < 10 ? `0${month}` : month;

    let day = date.getDay();
    day = day < 10 ? `0${day}` : day;

    let hour = date.getHours();
    hour = hour < 10 ? `0${hour}` : hour;

    let minute = date.getMinutes();
    minute = minute < 10 ? `0${minute}` : minute;

    return `${date.getFullYear()}-${month}-${day} ${hour}-${minute}`;
}
