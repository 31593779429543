/**
 * Данные для вкладки "Сервис сменных ковров"
 * changesPerWeek - количество замен в неделю,
 * rate1change - цена одной замены,
 * rate4weeks - цена замен за 4 недели
 * rate1_1 - deprecated.
 *
 * Например: колво замен в неделю 3, цена одной замены 84, цена за 4 недели: 84 * 3 * 4 = 1008
 */

export const CHANGABLE_MATRIX = window.data?.changableMatrices?.sort(function(a, b) {
    return a.width * a.height > b.width * b.height ? 1 : -1;
});
export const INTERNAL_CHANGABLE_MATRIX = CHANGABLE_MATRIX;

/**
 * Цены для вкладки "Модульные покрытия"
 */
export const MODULAR_COVER_PRICES = window.data?.modularCoverPrices;
export const ADDITIONAL_PRICES = window.data?.additionalPrices;
