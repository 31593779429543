<template>
    <div id="popup_holder">
        <div class="popup-block ">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Wrap"
    }
</script>

<style scoped>

</style>
