import {COVERTYPES, DIRECTIONS} from "@/constants/covers";
import {
    ADDITIONAL_PRICES,
    CHANGABLE_MATRIX,
    INTERNAL_CHANGABLE_MATRIX,
    MODULAR_COVER_PRICES
} from "../constants/prices";

export const Position = function (_type, is_internal=false) {
    var width = 300, height = 300,
        type = _type ? _type : COVERTYPES.ARABESKA,
        qty = 1,
        border = {
            LEFT: false,
            TOP: false,
            RIGHT: false,
            BOTTOM: false
        },
        direction = DIRECTIONS.RIGHT,
        changesQty = 1,
        color = null;

    // size for COVERTYPES.CHANGABLE
    if (type === COVERTYPES.CHANGABLE) {
        width = 85;
        height = 150;
    }

    // methods
    var getDimension = function () {
            return {
                width: parseInt(width),
                height: parseInt(height)
            };
        },
        getType = function () {
            return type;
        },
        setDimension = function (dim) {
            width = dim.width;
            height = dim.height;
        },
        getQty = function () {
            return qty;
        },
        setQty = function (_qty) {
            qty = parseInt(_qty);
        },
        getBorder = function () {
            return border;
        },
        setBorder = function (_border) {
            border = _border;
        },
        getDirection = function () {
            return direction;
        },
        setDirection = function (_dir) {
            direction = _dir;
        },
        getChangesQty = function () {
            return changesQty;
        },
        setChangesQty = function (_qty) {
            changesQty = parseFloat(_qty);
        },
        setColor = function(_color) {
            color = _color
        },
        getColor = function() {
            return color;
        };

    this.id = 'pos' + (+new Date());

    this.getType = getType;
    this.getDimension = getDimension;
    this.setDimension = setDimension;
    this.getQty = getQty;
    this.setQty = setQty;
    this.getBorder = getBorder;
    this.setBorder = setBorder;
    this.getDirection = getDirection;
    this.setDirection = setDirection;
    this.getChangesQty = getChangesQty;
    this.setChangesQty = setChangesQty;
    this.setColor = setColor;
    this.getColor = getColor;

    // calculate
    this.getPrice = function (changesQty) {
        const dim = this.getDimension();
        const modularCoverPrices = MODULAR_COVER_PRICES;

        if (type === COVERTYPES.CHANGABLE) {
            let totalCost = 0;
            const matrix = is_internal ? INTERNAL_CHANGABLE_MATRIX : CHANGABLE_MATRIX;
            const foundItems = matrix.filter(x => x.width === dim.width && x.height === dim.height);

            if (foundItems.length === 0) {
                return null;
            }

            const pricePos = foundItems[0];
            const rateFromPriceList = pricePos.rates.filter(x => x.changes_per_week === changesQty)[0];

            totalCost += rateFromPriceList.rate_4_weeks;

            // multiply by qantity
            totalCost = totalCost * this.getQty();

            return {
                text: 'Ковер ' + dim.width + ' x ' + dim.height,
                cost: totalCost,
                costOneChange: rateFromPriceList.rate_1_change
            };
        }

        if (type === COVERTYPES.ARABESKA || type === COVERTYPES.CLEANSTEP) {
            let border = this.getBorder();
            let dir = this.getDirection();

            dim.height = parseInt(dim.height, 10);
            dim.width = parseInt(dim.width, 10);
            const foundPrice = modularCoverPrices.find(item =>
                item.type === type
            );
            const pricePerUnit = foundPrice.price;

            var borderLen = (border.LEFT ? dim.height : 0) +
                (border.RIGHT ? dim.height : 0) +
                (border.TOP ? dim.width : 0) +
                (border.BOTTOM ? dim.width : 0),
                sq = dim.width * dim.height,
                tWidth = Math.ceil(dim.width * 10 / foundPrice.width),
                tHeight = Math.ceil(dim.height * 10 / foundPrice.height),
                quant = tWidth * tHeight;

            // CLEANSTEP quant correction
            if (type === COVERTYPES.CLEANSTEP) {
                // console.log(dir);
                let wq, hq;
                switch (dir) {
                    case 'UP':
                        wq = dim.width * 10 / foundPrice.width;
                        hq = dim.height * 10 / foundPrice.height;
                        break;

                    case 'RIGHT':
                    default:
                        wq = dim.height * 10 / foundPrice.width;
                        hq = dim.width * 10 / foundPrice.height;
                        break;
                }

                quant = Math.ceil(wq * hq);
            }

            if (border.LEFT && border.TOP) {
                borderLen += 14;
            }

            if (border.LEFT && border.BOTTOM) {
                borderLen += 14;
            }

            if (border.RIGHT && border.TOP) {
                borderLen += 14;
            }

            if (border.RIGHT && border.BOTTOM) {
                borderLen += 14;
            }

            return {
                quant: quant,
                quantPrice: quant * pricePerUnit,
                border: (borderLen / 100),
                borderPrice: (borderLen / 100) * ADDITIONAL_PRICES[0].price,
                fstn: (sq / 10000) * 10,
                fstnPrice: (sq / 10000) * 10 * ADDITIONAL_PRICES[1].price,
            };
        }
    };
}; // eof CC.Position
